.exercise-card .box {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    left: 0;
    margin-left: 16px;
    line-height: 54px;
    width: 54px;
    height: 54px;
    border-radius: 0;
    position: absolute;
    background-color: #D9D9D9;
    transition: 0.3s all ease-in;
}
.exercise-card .box span {
    color: #000;
}
.exercise-card.exercise-done .box {
    background-color: #4CAF50;
    color: white;
}
.exercise-card .toggle-in {
    border: 1px solid #D9D9D9;
    border-radius: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;        
} 
.exercise-card .toggle-out {
    border: 1px solid #4CAF50;
    border-radius: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;    
} 
.exercise-card .toggle-in span, .exercise-card .toggle-out span {
    margin-left: 10px;
    text-transform: uppercase;
    font-weight: bold;
}