.page {
    transition: transform 300ms, opacity 300ms;
    opacity: 0;
    transform: translateX(100%);
  }
  
  .page-enter {
    opacity: 1;
    transform: translateX(0);
  }
  
  .page-exit {
    opacity: 0;
    transform: translateX(-100%);
  }
  