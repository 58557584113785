
.only-printable {
  display: none;
}
@media print {
    .no-print {
      display: none;
      background-color: red;
    }
    @page {
      margin: 0;
    }
    body {
      margin: 0;
    }
    .only-printable {
      display:  flex;
    }
  }