
  @media print {
    .no-print {
    display: none;
    }
    @page {
    margin: 0;
    }
    body {
    margin: 0;
    }
}